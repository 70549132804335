<template>
    <v-sheet>
        <v-tabs
            v-model="tabComponente"
            dark
            fixed-tabs
            background-color="tnlogAzulEscuro"
        >
            <v-tab v-for="(item, index) in form.tabsConteudo" :key="index">
                {{ index }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabComponente">
            <div class="my-4 bg-grey">
                        <Avatar
                            url="/img/upload"
                        />
                    </div>
                    <v-divider></v-divider>
            <v-form :ref="formNome" dense class="my-4">
                <!--
                    DADOS
                 -->
                <v-tab-item eager class="py-4">
                    <v-row>
                        <v-col class="pt-0" cols="12" sm="2">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.id_usuario"
                                :form="form"
                                campo="id_usuario"
                                dense
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" md="2" style="margin-top: -22px">
                            <ButtonSwitchV2
                                dense
                                v-model="form.tabsConteudo.dados.interno"
                                :form="form"
                                campo="interno"
                                outlined
                                clearable
                            />
                        </v-col>
                        <v-col class="pt-0" cols="10" sm="2">
                            <v-autocomplete
                                dense
                                v-model="form.tabsConteudo.dados.id_parceiro"
                                :items="itemsParceiro"
                                outlined
                                label="Parceiro"
                                item-text="label"
                                item-value="value"
                                clearable
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="3">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.login"
                                :form="form"
                                campo="login"
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="3">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.senha"
                                :form="form"
                                campo="senha"
                                type="password"
                                dense
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="6">
                            <InputTextV2
                                dense
                                v-model="form.tabsConteudo.dados.nome_completo"
                                :form="form"
                                campo="nome_completo"
                            />
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="6">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.email"
                                :form="form"
                                campo="email"
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-form>
        </v-tabs-items>

        <BuscaModal
            ref="BuscaModal"
            @inputAutoCompletaAtualizaValor="inputAutoCompletaAtualizaValor"
            @mensagemAlerta="$emit('mensagemAlerta', $event)"
        />
    </v-sheet>
</template>

<script>
import _ from 'lodash';

import { API_URL } from '@/config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';
import {
    Maximo,
    Minimo,
    Obrigatorio,
    Email,
} from '@/helpers/ValidadorCampo.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import InputTextV2 from '@/components/Form/InputTextV2.vue';
import TextAreaV2 from '@/components/Form/TextAreaV2.vue';
import ButtonSwitchV2 from '@/components/Form/ButtonSwitchV2.vue';
import Avatar from '@/components/Upload/Avatar.vue';

export default {
    validaSenha: [
        (v) => v.length >= 4 || 'Senha tem que ser maior que 4',
    ],
    components: {
        BuscaModal,
        InputTextV2,
        TextAreaV2,
        ButtonSwitchV2,
        Avatar,
    },
    props: ['refBuscaModal'],
    async created() {
        this.formOriginal = _.cloneDeep(this.form);
    },
    watch: {},
    computed: {},
    mounted() {
        this.getParceiro();
    },
    methods: {
        preencheFormulario(conteudo) {
            this.form.tabsConteudo.dados = _.pick(
                conteudo.atributos,
                Object.keys(this.form.tabsConteudo.dados)
            );
            this.form.tabsConteudo.dados.id_usuario = conteudo.id_registro;
            this.form.tabsConteudo.dados.interno = conteudo.atributos.interno
                ? 'S'
                : 'N';
        },
        FormataDadosParaEnviarForm() {
            let form = [];
            let dados = Object.assign(this.form.tabsConteudo.dados);
            dados.interno = dados.interno === 'S' ? true : false;
            form = {
                dados,
            };

            return form;
        },
        async novoForm() {
            try {
                if (this.urlNovoRegistro == '') {
                    this.$store.commit('FORM_ACAO', 'form-novo');
                    this.$store.dispatch('painelAbre');

                    return false;
                }

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlNovoRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-novo');

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async consultaRegistro(id_registro) {
            try {
                this.tabComponente = 'Dados';

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlConsultaRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-atualiza');

                        this.preencheFormulario(resposta.data.data);

                        if (resposta.data.data.hasOwnProperty('complemento')) {
                            this.form.complemento = {
                                ...this.form.complemento,
                                ...resposta.data.data.complemento,
                            };
                        }

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        fechaForm() {
            try {
                this.$store.commit('FORM_ACAO', 'form-grid');
                this.limpaFormulario();
                this.$emit('datatableRecarrega');
                this.$store.dispatch('painelFecha');
                window.scrollTo(0, 0);
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async salvaForm() {
            try {
                if (!this.$refs[this.formNome].validate()) {
                    this.$emit(
                        'mensagemAlerta',
                        'Preencha corretamente todos campos',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario =
                            this.FormataDadosParaEnviarForm(
                                this.form.tabsConteudo
                            );

                        //console.log('envia form', conteudoFormulario);
                        //return;

                        let urlForm = conteudoFormulario.dados[
                            this.chavePrimaria
                        ]
                            ? `${API_URL.erp[this.moduloNome].salva}/${
                                  conteudoFormulario.dados[this.chavePrimaria]
                              }`
                            : `${API_URL.erp[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: MetodoNovoOuAtualizaApi(
                                conteudoFormulario.dados[this.chavePrimaria]
                            ),
                            url: urlForm,
                            data: conteudoFormulario,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$store.commit(
                                    'FORM_ACAO',
                                    'form-atualiza'
                                );

                                this.preencheFormulario(resposta.data.data);

                                conteudoFormulario.dados.id_usuario =
                                    resposta.data.data.id_registro;

                                this.$emit(
                                    'mensagemAlerta',
                                    `Usuario ${
                                        conteudoFormulario.dados[
                                            this.chavePrimaria
                                        ]
                                    } - ${conteudoFormulario.dados.nome_completo} ${
                                        conteudoFormulario.dados[
                                            this.chavePrimaria
                                        ]
                                            ? 'atualizado'
                                            : 'criado'
                                    } com sucesso`,
                                    'success'
                                );

                                if (
                                    resposta.data.data.hasOwnProperty(
                                        'complemento'
                                    )
                                ) {
                                    this.form.complemento = {
                                        ...this.form.complemento,
                                        ...resposta.data.data.complemento,
                                    };
                                }
                                this.fechaForm();
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(
                    this.moduloNome
                );

                let ids = dados
                    .map(function (dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit(
                        'mensagemAlerta',
                        'Selecione um registro antes de apagar.',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`,
                        };

                        let dados = await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$emit('datatableRecarrega');

                                this.$emit(
                                    'mensagemAlerta',
                                    `Registro ${ids} Deletado com sucesso`,
                                    'success'
                                );
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        limpaFormulario() {
            //this.$refs[this.formNome].reset();

            this.form = _.cloneDeep(this.formOriginal);
        },
        atualizaFormComplemento(complemento) {
            Object.keys(this.form.complemento).map((chave, idx) => {
                if (complemento[chave]) {
                    this.form.complemento[chave] = complemento[chave];
                }
            });

            this.formOriginal.complemento = this.form.complemento;
        },
        abrePesquisa(
            objetoClicado,
            objetoRetorno,
            formatoRetornado,
            titulo,
            datatableBusca
        ) {
            this.$refs['BuscaModal'].abreModal = true;
            this.$refs['BuscaModal'].titulo = titulo;
            this.$refs['BuscaModal'].objetoClicado = objetoClicado;
            this.$refs['BuscaModal'].objetoRetorno = objetoRetorno;
            this.$refs['BuscaModal'].formatoRetornado = formatoRetornado;
            this.$refs['BuscaModal'].datatableBusca = datatableBusca;
        },
        inputAutoCompletaAtualizaValor(objectoOpcoes, valorDefinido) {
            this.$refs['BuscaModal'].objetoClicado.atualizaValor(
                objectoOpcoes,
                valorDefinido
            );
        },

        async getParceiro() {
            await ApiService({
                method: 'get',
                url: `V1/parceiro/lista?fields=nome`,
                data: {}
            })
                .then(resposta => {
                    this.itemsParceiro = resposta.data.data.map(item => {
                        return {
                            label: item.atributos.nome,
                            value: item.id_registro
                        };
                    });
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        },
    },
    data() {
        return {
            itemsParceiro: [],
            apiUrl: API_URL,
            tabComponente: 'Dados',
            formNome: 'formUsuario',
            moduloNome: 'usuario',
            chavePrimaria: 'id_usuario',
            urlNovoRegistro: '',
            urlConsultaRegistro: 'busca',
            formOriginal: {},
            itemsSwitch: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
            ],
            form: {
                tabsConteudo: {
                    dados: {
                        id_usuario: '',
                        nome_completo: '',
                        email: '',
                        login: '',
                        senha: '',
                        interno: false,
                        id_parceiro: null,
                    },
                },
                complementoOriginal: {},
                complemento: {},
                configuracao: {
                    campo: {},
                    campoConfiguracao: {
                        id_usuario: {},
                        nome_completo: {},
                        email: {},
                        interno: false,
                        login: {},
                        senha: {},
                        id_parceiro: {},
                    },
                    tamanho: {
                        id_usuario: {},
                        nome_completo: { min: 2, max: 80 },
                        email: { max: 80 },
                        login: { min: 6, max: 20 },
                        senha: { min: 8, max: 60 },
                        interno: {min: 1, max:1},
                        id_parceiro: {},
                    },
                    regra: {
                        id_usuario: [],
                        nome_completo: [Obrigatorio(), Minimo(2), Maximo(80)],
                        email: [Maximo(80)],
                        login: [Obrigatorio(), Minimo(6), Maximo(20)],
                        senha: [],
                        interno: [Minimo(1), Maximo(1)],
                        id_parceiro: [],
                    },
                    placeholder: {
                        id_usuario: 'ID',
                        nome_completo: 'Nome',
                        email: 'E-Mail',
                        login: 'Login',
                        senha: 'Senha',
                        interno: 'Interno',
                        id_parceiro: 'Parceiro',
                    },
                    colunas: {},
                    obrigatorio: {
                        id_usuario: false,
                        nome_completo: true,
                        email: true,
                        login: true,
                        senha: false,
                        interno: true,
                        id_parceiro: true,
                    },
                    desativado: {
                        id_usuario: true,
                        nome_completo: false,
                        email: false,
                        login: false,
                        senha: false,
                        interno: false,
                        id_parceiro: false
                    },
                    dataTableVuetify: {},
                },
            },
        };
    },
};
</script>

<style scoped>
.myDiv {
    padding-top: 20px;
}
</style>
